
import { Service } from '@/services/service'

const PATH = 'user/profile-rcs/'

class ProfileRcsService extends Service {
  findAll (params = {}) {
    return this.get(PATH + 'index', params)
  }

  find (id, params = {}) {
    return this.get(PATH + 'show/' + id, params)
  }

  create (params) {
    return this.post(PATH + 'store', params)
  }

  update (params) {
    return this.post(PATH + 'update', params)
  }

  delete (params) {
    return this.post(PATH + 'delete', params)
  }

  sendVerify (params) {
    return this.post(PATH + 'validate/pending', params)
  }
}

export default new ProfileRcsService()
