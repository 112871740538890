<template>
  <div>
    <header-top-dashboard neutral />
    <v-container
      fluid
      class="pt-0 px-6 mt-n16 container-general"
    >
      <v-row>
        <v-col
          cols="12"
          class="mt-n3"
        >
          <v-card class="card-shadow v-card-grey px-0 py-0">
            <v-card-title class="pt-2 pb-2 mb-0 card-title-bloque">
              <div class="w100">
                <p class="tit_card inline-block pt-2">
                  {{ $t('Perfil RCS') }}
                </p>
              </div>
            </v-card-title>

            <div class="card-header-padding">
              <profile-rcs-list />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
  import ProfileRcsList from '../../../../components/ProfileRCS/ProfileRCSList.vue'

  export default {
    name: 'PageProfileRcsList',
    components: {
      HeaderTopDashboard,
      ProfileRcsList,
    },
  }
</script>
<style scoped>
</style>
