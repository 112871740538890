import EventBus from '@/util/EventBus'

import ProfileRcsService from '../../services/profile-rcs/profile-rcs.service'

export default {
  name: 'ProfileRcsList',
  props: {},
  components: {},
  data: function () {
    return {
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      total: 0,
      disabledPagination: true,
      options: {},
      searchTerm: '',
      profiles: [],
      modalProfile: {
        show: false,
        item: {},
      },
      loading: false,
      tableAction: '',
    }
  },
  created () {},
  mounted () {},
  computed: {
    headers () {
      return [
        {
          text: this.$t('Agente'),
          value: 'agent_name',
          sortable: false,
        },
        {
          text: this.$t('País'),
          value: 'country',
          sortable: false,
        },
        {
          text: this.$t('Tipo'),
          value: 'user_profile_rcs_message_type_id',
          sortable: false,
        },
        {
          text: this.$t('Actualización'),
          value: 'updated_at',
          sortable: false,
        },
        {
          text: this.$t('Estado'),
          value: 'profile_rcs_status.name',
          sortable: false,
        },
        {
          text: this.$t('Acciones'),
          value: 'actions',
          sortable: false,
        },
      ]
    },
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
      }
    },
  },
  watch: {
    params: {
      handler () {
        this.getProfilesRcs()
      },
      deep: true,
    },
  },
  methods: {
    getParams () {
      const page = this.tableAction === 'DELETE' && this.profiles.length === 0 && this.options.page > 1
        ? this.options.page - 1
        : this.options.page
      this.tableAction = null

      const params = {
        page: page,
        per_page: this.options.itemsPerPage ?? this.itemsPerPage,
        searchTerm: this.searchTerm,
      }
      return params
    },
    getProfilesRcs () {
      this.disabledPagination = true
      const data = this.getParams()
      this.profiles = []
      ProfileRcsService.findAll(data)
      .then(
        (response) => {
          this.profiles = response.data.data
          this.disabledPagination = false
          this.itemsPerPage = parseInt(response.data.per_page)
          this.pageCount = response.data.last_page
          this.page = response.data.current_page
          this.total = response.data.total
        },
        () => {
          EventBus.$emit('showAlert', 'danger', this.$t('Hubo un problema al intentar ver los perfiles'))
        },
      )
    },
    createProfile (item) {
      this.$router.push('/users/profile-rcs/create')
    },
    viewProfile (item) {
      this.$router.push(`/users/profile-rcs/create?id=${item.id}`)
    },
    openModalProfile (item) {
      this.modalProfile.show = true
      this.modalProfile.item = item
    },
    closeModalProfile () {
      this.modalProfile.show = false
      this.modalProfile.item = {}
    },
    deleteProfile () {
      this.loading = true
      const data = {
        id: this.modalProfile.item.id,
      }

      ProfileRcsService.delete(data)
      .then(() => {
        this.tableAction = 'DELETE'
        this.profiles = this.profiles.filter(profile => profile.id !== this.modalProfile.item.id)
        this.getProfilesRcs()
        EventBus.$emit('showAlert', 'success', this.$t('Perfil eliminado con éxito'))
      })
      .catch(() => {
        EventBus.$emit('showAlert', 'danger', this.$t('Ocurrió un error al eliminar el perfil'))
      })
      .finally(() => {
        this.loading = false
        this.closeModalProfile()
      })
    },
    userProfileTypes (item) {
      return JSON.parse(item.user_profile_rcs_message_type_id)
    },
  },
}
